import React from 'react';
import { MainContainer } from '../../components/template/MainContainer';

export default () => {
    return (
        <MainContainer headerData={{ title: `Desconto em Equipamento`, subtitle: `Aprovação` }}>
            <div className="d-flex flex-column justify-content-center align-items-center text-center" style={{ height: '60vh' }}>
                <i className="fas fa-laptop-code fa-4x text-secondary mb-3"></i>
                {/* <i className="fa-solid fa-wrench fa-3x text-secondary mb-3"></i> */}
                
                <h4 className="fw-bold text-secondary">Em desenvolvimento</h4>
                <p className="text-muted">Estamos trabalhando nessa funcionalidade. Em breve, novidades!</p>
                <i className="fa-solid fa-hourglass-half fa-3x text-secondary mb-4"></i>
            </div>
        </MainContainer>
    );
};
