import React from 'react';
import { useLocation } from 'react-router-dom';
import './DetalhePedidoCompra.css';
import { MainContainer } from '../../../components/template/MainContainer';
import DetalhePedidoCompraItens from './DetalhePedidoCompraItens';
import { formatarData } from '../../../utils/formatarDataHora';
import PopupRejeitar from './PopupRejeitar';
import PopupLiberar from './PopupLiberar';
import { useAuth } from '../../../context/AuthContext';

const DetalhePedidoCompra = () => {
    const { user } = useAuth();
    const location = useLocation();
    const data = location.state?.data;

    // Verifique se os dados estão disponíveis
    if (!data) {
        return <p>Dados do orçamento não disponíveis.</p>;
    }

    const handleAction = (action) => {
        // console.log(`${action} confirmado para orçamento ${data?.orcamento}`);
        // Adicione aqui a lógica para lidar com a ação
    };
    // console.log(data)
    return (
        <MainContainer headerData={{ title: `Pedido de Compras`, subtitle: `Aprovação` }}>

            <div className="container-fluid detalhe-orcamento-container themeTextColorPrimary mb-2">
                {/* Informações principais */}
                <div className="row mb-2">
                    <div className="col-12">
                        <label>Fornecedor:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={`${data?.codi_forne}/${data?.loja_forne} - ${data?.fornecedor}` ?? 'Informação da empresa não disponível'}
                            readOnly
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label>Emissão:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formatarData(`${data?.emissao}`)}
                            readOnly
                        />
                    </div>
                    <div className="col-6">
                        <label>Usuário:</label>
                        <input type="text" className="form-control" value={data?.usuario_inc} readOnly />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label>Pedido:</label>
                        <input type="text" className="form-control" value={data.pedido} readOnly />
                    </div>
                    <div className="col-6">
                        <label>Filial:</label>
                        <input type="text" className="form-control" value={data?.filial} readOnly />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <label>Limite:</label>
                        <input type="text" className="form-control" value={data.lim_aprova} readOnly />
                    </div>
                    <div className="col-4">
                        <label>Saldo:</label>
                        <input type="text" className="form-control" value={data?.sld_data} readOnly />
                    </div>
                    <div className="col-4">
                        <label>Total:</label>
                        <input type="text" className="form-control" value={data.vlr_total} readOnly />
                    </div>
                </div>

                <DetalhePedidoCompraItens
                    IDUSR={user?.id}
                    FIL={data?.filial}
                    NUMPC={data?.pedido}
                    IDAPRV={data?.aprovador}
                />

                {/* Botões de ação */}
                <div className="row mt-4">
                    <div className="col-6">
                        <PopupRejeitar
                            onConfirm={handleAction}
                            NUMPC={data?.pedido}
                            FIL={data?.filial}
                            IDUSR={user?.id}
                        />
                    </div>
                    <div className="col-6">
                        <PopupLiberar
                            onConfirm={handleAction}
                            NUMPC={data?.pedido}
                            FIL={data?.filial}
                            IDUSR={user?.id}
                        />
                    </div>
                </div>


            </div>
        </MainContainer>
    );
};

export default DetalhePedidoCompra;
