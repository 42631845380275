import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CabecalhoBotao.css';
import { formatarData } from '../../utils/formatarDataHora';

const CabecalhoBotao = ({ data }) => {
    const navigate = useNavigate();

    const title = `${data?.filial}` ?? 'Título padrão';
    const companyInfo = `${data?.codi_forne}/${data?.loja_forne} - ${data?.fornecedor}` ?? 'Informação da empresa não disponível';
    const orderInfo = `Pedido: ${data?.pedido}` ?? 'Informação do pedido não disponível';
    let dateInfo = `${data?.emissao}` ?? 'Data não disponível';
    const aprovador = `Aprovador: ${data?.aprovador} - ${data?.nome_aprova}` ?? 'Identificação não disponível';
    const usuarioInclusao = `Usuário: ${data?.usuario_inc}` ?? 'Pessoa de contato não disponível';

    dateInfo = formatarData(dateInfo)

    return (
        <div className="container container-box themeTextColorPrimary mb-2" role="button"
            onClick={() => navigate("/aprovacao_pedido_compra_detalhe", { state: { data } })}
        >
            <div className="row">
                <div className="col-12">
                    <h5 className="fw-bold">{title}</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <p className="custom-text mb-0">{companyInfo}</p>
                    <p className="custom-text mb-0">{orderInfo}</p>
                    <p className="custom-text mb-0">{dateInfo}</p>
                </div>
                <div className="col-4 d-flex flex-column justify-content-end text-end">
                    <p className="custom-text mb-0">{aprovador}</p>
                    <p className="custom-text mb-0">{usuarioInclusao}</p>
                </div>
            </div>

        </div>
    );
};

export default CabecalhoBotao;
