export const formatarDataHora = (input) => {
  // Extrai a data e a hora da string
  const dataPart = input.split(' - ')[0];
  const horaPart = input.split(' - ')[1];

  // Divide os componentes da data
  const ano = dataPart.slice(0, 4);
  const mes = dataPart.slice(4, 6);
  const dia = dataPart.slice(6, 8);

  // Divide os componentes da hora
  const hora = horaPart.slice(0, 2);
  const minutos = horaPart.slice(2, 4);

  // Monta a data no formato brasileiro
  return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
};

export const formatarData = (input) => {
  // Extrai a data e a hora da string
  const dataPart = input.split(' - ')[0];
  const horaPart = input.split(' - ')[1];

  // Divide os componentes da data
  const ano = dataPart.slice(0, 4);
  const mes = dataPart.slice(4, 6);
  const dia = dataPart.slice(6, 8);

  // Monta a data no formato brasileiro
  return `${dia}/${mes}/${ano}`;
};
