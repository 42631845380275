import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchData } from '../../../utils/datasource';
import { useNavigate } from 'react-router-dom';

const PopupRejeitar = ({ onConfirm, NUMPC, FIL, IDUSR }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [validationError, setValidationError] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        if (isPopupVisible) {
            // fetchMotivos();
        }
    }, [isPopupVisible]);

    const handleClick = () => {
        setIsPopupVisible(true);
    };

    const handleConfirm = async () => {

        try {
            const url = `/DCAPROVPC/api/dc/getaprpc?IDUSR=${IDUSR}&FIL=${FIL}&NUMPC=${NUMPC}&TPRESP=R&OBSPC=OBS`

            const response = await fetchData(url, 'GET');

            if (response.ok) {
                window.showAlert('success', 'Rejeição confirmada com sucesso!');
                if (onConfirm) onConfirm({ action: 'Rejeitar', motivo: 'selectedMotivo' });
                navigate('/aprovacao_pedido_compra'); // Redireciona após sucesso
            } else {
                window.showAlert('danger', 'Erro ao confirmar a rejeição.');
            }
        } catch (err) {
            window.showAlert('danger', 'Erro ao confirmar a rejeição. Verifique sua conexão ou os dados enviados.');
        } finally {
            setIsPopupVisible(false);
        }
    };

    const handleCancel = () => {
        setIsPopupVisible(false);        
        setValidationError(''); // Limpa o erro ao cancelar
    };

    return (
        <>
            <button className="btn btn-danger w-100" onClick={handleClick}>
                Rejeitar
            </button>
            {isPopupVisible && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <h5>Confirmar Rejeição</h5>
                        <p>Você tem certeza que deseja rejeitar este pedido de compras?</p>
                        <div className="modal-actions">
                            <button className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                            <button className="btn btn-danger" onClick={handleConfirm}>
                                Confirmar
                            </button>
                        </div>
                        {/* Mensagem de validação */}
                        {validationError && <p className="text-danger mt-2">{validationError}</p>}
                    </div>
                </div>
            )}
        </>
    );
};

PopupRejeitar.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    NUMPC: PropTypes.string.isRequired,
    FIL: PropTypes.string.isRequired,
    IDUSR: PropTypes.string.isRequired,
};

export default PopupRejeitar;
