// import React from 'react';
import './DetalhePedidoCompraItens.css';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchData } from '../../../utils/datasource';

// const DetalheOrcamentoItens = () => {

const DetalhePedidoCompraItens = ({ IDUSR, FIL, NUMPC, IDAPRV }) => {
    const [itens, setItens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchItens = async () => {
            try {
                console.log('getItens')
                setLoading(true); // Inicia o carregamento
                const response = await fetchData(`/DCAPROVPC/api/dc/getitepc?IDUSR=${IDUSR}&FIL=${FIL}&NUMPC=${NUMPC}&IDAPRV=${IDAPRV}`, 'GET');





                if (!response.ok) {
                    throw new Error('Erro ao buscar os itens.');
                }

                const result = await response.json();

                console.log(result)
                setItens(result.DET_PEDIDO); // Atualiza o estado com os itens recebidos
            } catch (err) {
                setError(err.message); // Captura o erro
            } finally {
                setLoading(false); // Finaliza o carregamento
            }
        };

        fetchItens();
    }, [IDUSR, FIL, NUMPC, IDAPRV]);

    if (loading) {
        return <p>Carregando itens...</p>;
    }

    if (error) {
        return <p className="text-danger">Erro: {error}</p>;
    }

    if (itens.length === 0) {
        return <p>Nenhum item encontrado.</p>;
    }



    // return <>ITENS</>

    return <>
        <div className="section-header fw-bold h6 mt-4 themeBGColorPrimary themeTextColorSecondary borderRadius2">Peças:</div>

        <div className="items-container">
            {itens.map((item, index) => (
                <div key={index} className="item-box mb-2 p-2">
                    <div className="d-flex justify-content-between align-items-center mb-2 item-header">
                        <strong>{item.codi_prod} - {item.desc_prod}</strong>
                        <strong>{item.price}</strong>
                    </div>

                    <div className="row text-center">
                        <div className="col-3">
                            <p>Unidade</p>
                            <p>{item.unid_prod}</p>
                        </div>
                        <div className="col-3">
                            <p>Quantidade</p>
                            <p>{item.qtde_prod}</p>
                        </div>
                        <div className="col-3">
                            <p>Valor</p>
                            <p>{item.prc_prod}</p>
                        </div>

                        <div className="col-3">
                            <p>Total</p>
                            <p>{item.tot_prod}</p>
                        </div>
                    </div>

                    {item.observacao && (
                        <div className="row text-left">
                            <div className="col-12">
                                <p>Observação</p>
                                <p>{item.observacao}</p>
                            </div>
                        </div>
                    )}


                </div>
            ))}
        </div>
    </>

};

export default DetalhePedidoCompraItens;
