import React, { useState, useCallback } from 'react';
import './Login.css';
import { Logo } from './template/Logo';
import { useAuth } from '../context/AuthContext';
import packageJson from '../../package.json'; // Importando a versão do package.json

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [urlLogin, setUrlLogin] = useState('');
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  
  


  // Função para reduzir o número para 4 dígitos
  const getReducedBuildNumber = (build) => {
    if (build === 'N/A') return build; // Se não houver build, retorna "N/A"

    const buildString = build.toString();
    const length = buildString.length;

    // Define o divisor ideal para obter 4 dígitos
    const divisor = Math.pow(10, length - 4);

    // Reduzindo o número para 4 dígitos
    return Math.floor(parseInt(build, 10) / divisor);
  };

  const buildNumber = getReducedBuildNumber(process.env.REACT_APP_BUILD_NUMBER || '9258852470');

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await signIn(username, password);
    setLoading(false);

    if (!result.isOk) {
      setErrorText(result.message);
      setUrlLogin(localStorage.getItem('urllogin'))
      console.error(result.message);
    }
  }, [username, password, signIn]);

  return (
    <div className="container-fluid p-0 d-flex flex-column justify-content-center align-items-center min-vh-100 text-center">
      <Logo />

      <div className="login-box text-center p-2">
        <form onSubmit={onSubmit}>
          <input
            type="text"
            className="form-control custom-input mb-1"
            id="username"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="form-control custom-input mb-3"
            id="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {urlLogin && <span className="error-text">{urlLogin}</span>}
          <button
            type="submit"
            className="btn btn-success-custom w-100 fw-bold themeBGColorSecondary themeTextColorPrimary"
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Acessar'}
          </button>
          {errorText && <span className="error-text">{errorText}</span>}
          

          
        </form>
      </div>

      {/* Rodapé com versão */}
      <div className="mt-3">
        <small className="text-muted">
        Build {buildNumber} by <a href="https://www.sibe.com.br" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-secondary">Sibe</a>
        </small>
      </div>
    </div>
  );
}

export default Login;
