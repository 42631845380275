import React from 'react';
import { MainContainer } from './template/MainContainer';
import { Logo } from './template/Logo';
import { Link } from 'react-router-dom';
import './Home.css';

const Menu = ({ label, disabled, link }) => {
  return (
    <div className="col-md-4 col-6 mb-3 d-flex">
      <Link to={link} className="btn shadow-sm flex-fill themeTextColorPrimary fw-bold custom-font-size btn-click-effect" aria-disabled={disabled}>
        {label}
      </Link>
    </div >
  );
};

function Home() {

  return (

    <MainContainer showHeader={false}>
      <Logo showLogout />


      <div className="container p-4">
        <div className="row ">
          <Menu label={"APROVAÇÃO DESCONTO OS/ORC."} link={"/aprovacao_desconto_os_orcamento"} />
          <Menu label={"APROVAÇÃO PEDIDO COMPRA"} link={"/aprovacao_pedido_compra"} />
          <Menu label={"APROVAÇÃO DESCONTO EQUIP."} link={"/aprovacao_desconto_equipamento"} />
        </div>
      </div>
    </MainContainer>
  )
}

export default Home;