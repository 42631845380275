import React, { useEffect, useState } from 'react';
import { MainContainer } from '../../components/template/MainContainer';
import Preloader from '../../components/template/Preloader';
import CabecalhoBotaoOrcamento from './CabecalhoBotaoOrcamento';
import { fetchData } from '../../utils/datasource';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';


export default () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const currentLocation = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        setLoading(true);
        const response = await fetchData(`/DCAPROVPED/api/dc/getcabped?IDUSER=${user?.id || 'Usuário não encontrado'}`, 'GET');
        const result = await response.json();
        setData(result.CABECALHO);
      } catch (err) {
        setError('Erro ao carregar os dados.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDataFromAPI();
  }, [currentLocation.pathname]); // Atualiza sempre que a rota mudar

  // if (loading) return <p>Carregando2...</p>;



  if (loading) return <Preloader />

  // if (error) return <p>{error}</p>;

  if (error) {
    navigate('/'); // Redireciona para a página inicial
    return null; // Evita renderização desnecessária
  }

  return (
    <MainContainer headerData={{ title: `Autorização Venda`, subtitle: `Liberação` }}>
      {data.map((item, index) => (
        <CabecalhoBotaoOrcamento key={index} data={item} />

      ))}
    </MainContainer>
  );
};
