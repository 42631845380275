import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchData } from '../../../utils/datasource';
import { useNavigate } from 'react-router-dom';
import './Popup.css';

const PopupLiberar = ({ onConfirm, NUMPC, FIL, IDUSR }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setIsPopupVisible(true);
    };

    const handleConfirm = async () => {
        setIsPopupVisible(false);
        if (onConfirm) onConfirm('Liberar');

        try {            
            const url = `/DCAPROVPC/api/dc/getaprpc?IDUSR=${IDUSR}&FIL=${FIL}&NUMPC=${NUMPC}&TPRESP=A&OBSPC=OBS`
            const response = await fetchData(url, 'GET');

            if (response.ok) {
                window.showAlert('success', 'Liberação confirmada com sucesso!');
                // if (onConfirm) onConfirm({ action: 'Libera', motivo: selectedMotivo });
                if (onConfirm) onConfirm('Liberar');
                navigate('/aprovacao_pedido_compra'); // Redireciona após sucesso
            } else {
                window.showAlert('danger', 'Erro ao confirmar a liberação.');
            }
        } catch (err) {
            window.showAlert('danger', 'Erro ao confirmar a liberação. Verifique sua conexão ou os dados enviados.');
        } finally {
            setIsPopupVisible(false);
        }


    };

    const handleCancel = () => {
        setIsPopupVisible(false);
    };

    return (
        <>
            <button className="btn btn-success w-100" onClick={handleClick}>
                Liberar
            </button>
            {isPopupVisible && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <h5>Confirmar Liberação</h5>
                        <p>Você tem certeza que deseja liberar este pedido de compras?</p>
                        <div className="modal-actions">
                            <button className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                            <button className="btn btn-success" onClick={handleConfirm}>
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

PopupLiberar.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    NUMPC: PropTypes.string.isRequired,
    FIL: PropTypes.string.isRequired,
    IDUSR: PropTypes.string.isRequired,
};

export default PopupLiberar;
